.article-showcase {
  margin: 25px 10px;
  padding: 0;
  display: flex;
  justify-content: left;
  background-color: #fff;
  border-radius: 15px;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.article-showcase:hover {
  transform: scale(1.01);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.article-showcase img {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 30vw;
  height: auto;
}

.article-summary {
  margin: 10px 20px;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  text-align: left;
}

.article-summary .title {
  font-weight: 700;
  font-size: 30px;
  color: #00265f;
}

.article-info {
  font-weight: 600;
  font-size: 20px;
}

.article-info .author {
  color: #0066ff;
}

.article-info .site {
  color: #3385ff;
  margin-left: 25px;
}

.summary {
  margin: 10px 0;
  font-size: 18px;
  text-align: justify;
}

.read-more {
  font-weight: 900;
  color: #0066ff;
}

@media (max-width: 1740px) {
  .article-showcase img {
    border-bottom-left-radius: 0%;
  }
}

@media (max-width: 1100px) {
  .article-showcase {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .article-showcase img {
    width: 100%;
    border-bottom-left-radius: 0%;
    border-top-right-radius: 15px;
  }
}

@media (max-width: 768px) {
  .article-summary .title {
    font-size: 26px;
  }

  .article-info {
    display: flex;
    flex-direction: column;
    font-size: 18px;
  }

  .article-info .site {
    margin: 0;
    padding: 0;
  }
  .summary {
    font-size: 14px;
  }
}
