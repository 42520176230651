.social-container {
  margin: 30px 15px;
  display: flex;
  align-items: center;
  width: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
}

.social-container:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
}

.social-container img {
  margin: 0;
  padding: 5px;
  width: auto;
  height: 60px;
  border-radius: 10px;
}

.social-container .siteName {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 1000;
  font-style: normal;
  font-size: 30px;
  color: #00265f;
  padding-left: 7px;
  padding-right: 15px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
