.image-container {
  text-align: center;
  margin: 20px;
}

.thumbnail {
  width: auto;
  max-height: 340px;
  cursor: pointer;
  transition: transform 0.3s;
}

.thumbnail:hover {
  transform: scale(1.05);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay-content {
  min-width: 80vw;
  height: auto;
  max-height: 90vh;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close {
  position: absolute;
  top: 20px;
  right: 40px;
  font-size: 40px;
  color: #3385ff;
  cursor: pointer;
  font-weight: 900;
}

.close:hover {
  color: #00265f;
}

.no-scroll {
  overflow: hidden;
}

@media (max-width: 768px) {
  .overlay-content {
    padding: 10px;
  }
}
