.workshop-page {
  min-height: 100vh;
}

.workshop-page-banner {
  display: flex;
  justify-content: center;
}

.workshop-page-banner img {
  margin: 0;
  padding: 0;
  width: 60vw;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.workshop-page ul {
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  list-style-type: none;
}

.workshop-page #additive-manufacturing {
  columns: 3;
}

.workshop-page #subtractive-manufacturing {
  columns: 2;
}

.workshop-page #soldering-equipment {
  columns: 3;
}

.workshop-page #testing-equipment {
  columns: 4;
}

.workshop-page #microcontrollers {
  columns: 6;
}

.workshop-page #single-board-computers {
  columns: 4;
}

.workshop-page #design {
  columns: 6;
}

.workshop-page #coding {
  columns: 5;
}

.workshop-page #cam {
  columns: 4;
}

.workshop-page li {
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 1000;
  font-style: normal;
  font-size: 30px;
  color: #0066ff;
  padding: 5px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1700px) {
  .workshop-page ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }

  .workshop-page li {
    font-size: 28px;
  }

  .workshop-page #subtractive-manufacturing {
    display: flex;
    justify-content: center;
  }

  .workshop-page #testing-equipment,
  .workshop-page #single-board-computers,
  .workshop-page #cam {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1100px) {
  .workshop-page-banner img {
    width: 78vw;
  }
}

@media (max-width: 768px) {
  .workshop-page-banner img {
    width: 84vw;
  }

  .workshop-page ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }

  .workshop-page li {
    font-size: 18px;
  }

  .workshop-page #subtractive-manufacturing {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .workshop-page #testing-equipment,
  .workshop-page #single-board-computers,
  .workshop-page #cam {
    grid-template-columns: repeat(1, 1fr);
  }
}
