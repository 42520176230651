.button {
  padding: 15px 20px;
  background-color: #0066ff;
  border: none;
  border-radius: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s;
}

.button:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.button-content {
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  font-size: 24px;
  color: #00265f;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.button-content .material-symbols-outlined {
  margin-right: 7px;
  font-weight: 900;
  font-style: normal;
  font-size: 24px;
}

@media (max-width: 768px) {
  .button {
    scale: 0.75;
  }
}
