.AboutMeSection {
  display: block;
  overflow: hidden;
}

.AboutMeSection-image {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  width: 21vw;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.AboutMeSection-summary {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-size: 27px;
  color: #0066ff;
  text-align: justify;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.AboutMeSection-summary b {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  font-size: 29px;
  color: #00265f;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.AboutMeSection-summary p {
  margin-top: 24px;
  margin-bottom: 24px;
}

@media (max-width: 1100px) {
  .AboutMeSection {
    display: block;
  }

  .AboutMeSection-summary p,
  .AboutMeSection-summary b {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .AboutMeSection-image {
    width: 150px;
    height: 150px;
  }

  .AboutMeSection-summary p,
  .AboutMeSection-summary b {
    font-size: 18px;
  }
}
