.copyright-notice {
  padding-left: 25px;
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 750;
  font-style: normal;
  font-size: 20px;
  color: #0066ff;
  text-align: justify;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 15px;
  padding: 10px 20px;
  text-align: center;
  box-shadow: 1px 2px rgba(0, 0, 0, 0.2);
}

.copyright-notice b {
  font-weight: 900;
  color: #00265f;
}

.copyright-notice a {
  display: inline-block;
}

.copyright-notice img {
  height: 22px;
  margin-left: 3px;
  vertical-align: text-top;
}

@media (max-width: 768px) {
  .copyright-notice {
    font-size: 18px;
  }

  .copyright-notice img {
    height: 20px;
  }
}
